<template>
  <div>
    
    <v-text-field  solo clearable
    prepend-inner-icon="mdi-magnify"
     class="rounded-lg" type="text"
        placeholder="Search title and description..."
        v-model.trim="input"
        v-on:input="search()"
        ref="input"
      background-color="secondary lighten-3" 
       > 

    </v-text-field>
   
 
  </div>
  
</template>


<script>
export default {
  data: function () {
    return {

      input: '',

      

  };
  },


  computed: {

  },


  methods: {

       clearList() {
     this.$store.dispatch("setBuyItemList");},

 search() {
      let array = this.$store.state.data.item.filter(item => !item.buyer && item.transferable === true)
      let rs = array.filter(item => item.description.toLowerCase().includes(this.input.toLowerCase()) || item.title.toLowerCase().includes(this.input.toLowerCase())
      );
      //this.applied.push(this.input)
      this.$store.commit("updateBuyItemList", rs);
       this.$router.push("/");
    },
    },
   

    

   


   
 
};
</script>
